import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const content = {
  title: 'Piotr Wąs',
  subtitle: 'Zapraszam do kontaktu',
  contacts: [
    {
      to: 'mailto: biuro@plisygrodzisk.pl',
      text: 'biuro@plisygrodzisk.pl',
      Icon: FaEnvelope,
      newTab: false,
    },
    {
      to: 'tel:+48780788668',
      text: '+48 780 788 668',
      Icon: FaPhoneAlt,
      newTab: false,
    },
  ],
  links: [],
};

export default content;
