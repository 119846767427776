import { FaFacebook, FaInstagram } from 'react-icons/fa';

const content = {
  tagline: 'Strona w budowie',
  title: 'Więcej informacji',
  subtitle: 'znajdziesz na naszych profilach w mediach społeczościowych',
  links: [
    {
      to: 'https://www.facebook.com/profile.php?id=100090867467982',
      text: 'Facebook',
      Icon: FaFacebook,
      newTab: true,
    },
    {
      to: 'https://instagram.com/plisygrodzisk.pl?igshid=YmMyMTA2M2Y=',
      text: 'Instagram',
      Icon: FaInstagram,
      newTab: true,
    },
  ],
};

export default content;
